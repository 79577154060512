import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Après avoir passé neuf mois à grossir, beaucoup de femmes ont hâte de retrouver leur silhouette d’avant grossesse. Mais perdre du poids après un accouchement n’est pas toujours facile.`}</p>
    <h2>{`Ne pas se précipiter`}</h2>
    <p>{`Il est important de ne pas se mettre trop de pression et de ne pas essayer de perdre trop de kilos trop vite. Il faut laisser le temps à votre corps de se remettre de la grossesse et de l’accouchement. Vous ne devriez pas essayer de faire un régime drastique ou de vous affamer pour perdre du poids.`}</p>
    <h2>{`Manger équilibré et faire de l’exercice`}</h2>
    <p>{`Pour perdre du poids de façon saine, il faut manger équilibré et faire de l’exercice. Vous devriez faire attention à ce que vous mangez et éviter les aliments gras, sucrés ou salés.`}</p>
    <p>{`Il est important de manger des fruits et des légumes, de la viande maigre, du poisson, des produits laitiers faibles en gras, des céréales et des légumes secs. Vous devriez aussi limiter votre consommation de sucre, de sel et de café.`}</p>
    <p>{`Faire de l’exercice régulièrement est aussi important. Vous pouvez commencer doucement avec quelques minutes de marche par jour. Vous pouvez ensuite augmenter progressivement le temps et l’intensité de vos séances d’exercice.`}</p>
    <h2>{`Se fixer des objectifs`}</h2>
    <p>{`Il est important de se fixer des objectifs réalisables et de ne pas abandonner si on ne voit pas de résultats immédiats. La perte de poids peut prendre du temps, mais si vous êtes patiente et que vous suivez un régime équilibré et que vous faites de l’exercice, vous finirez par perdre vos kilos de grossesse.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      